.ant-btn.ant-btn-background-ghost {
    border-color: @asphalt-7;
    color: @asphalt-7;
    &:disabled,
    [disabled] {
        background-color: #f5f5f5 !important;
    }
}
.ant-card-head {
    font-weight: 600;
    .ant-card-extra {
        font-weight: 600;
    }
}
.ant-typography {
    ul {
        li {
            list-style-type: none;
            margin: 0;
            padding: 0 0 0 10px;
            font-weight: normal;
            position: relative;
            &:before {
                content: '•';
                position: absolute;
                top: 0;
                left: 0;
                color: #08979c;
            }
        }
        &.strong li {
            font-weight: 600;
        }
    }
}
.ant-typography.main-title {
    font-size: 46px;
    font-weight: 600;
    margin-bottom: 0;
    letter-spacing: -0.03em;
    @media (max-width: 1023px) {
        font-size: 30px;
    }
    @media (max-width: 767px) {
        font-size: 26px;
    }

    &.sold {
        color: #8c8c8c;
    }

    &.main-title-md {
        font-size: 38px;
        @media (max-width: 1023px) {
            font-size: 30px;
        }
    }
}
.ant-typography.title-upcoming {
    color: @asphalt-7;
    margin: 0;
    line-height: 1.1;
    font-size: 24px;
    @media (max-width: 767px) {
        font-size: 20px;
    }

    &:hover {
        .anticon.disabled {
            color: @asphalt-7;
        }
    }

    .anticon {
        font-size: 21px;
        margin: 0 0 0 3px;
    }
}
.ant-typography .default-list {
    margin-left: 40px;
    li {
        padding: 0;
        font-weight: normal;
        list-style-type: disc;
        &:before {
            display: none;
        }
    }
}
.ant-form-inline .ant-form-item {
    margin-right: 0;
}
.form-sm-space {
    .ant-form-item {
        margin-bottom: 0;
    }
}
.ant-btn {
    box-shadow: none !important;
    &.danger-reverse {
        background-color: @red-1;
        color: @red-6;
        border-color: @red-1;
    }
}

.ant-table td {
    background-color: #fff;
}
.ant-table-tbody > tr.ant-table-row-selected td,
.ant-table-thead > tr:hover.ant-table-row-selected > td,
.ant-table-tbody > tr:hover.ant-table-row-selected > td {
    background: rgba(230, 255, 251, 0.5);
}
.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-sorters:hover {
    background: rgba(230, 255, 251, 0.5);
}
.ant-table-thead > tr > th .ant-table-header-column .ant-table-column-sorters::before {
    display: none;
}
.ant-table-thead > tr > th.ant-table-column-sort {
    background: rgba(230, 255, 251, 0.5);
}
.ant-dropdown-menu-item:hover,
.ant-dropdown-menu-submenu-title:hover {
    background-color: @asphalt-1;
}
.ant-dropdown-menu-item > a:hover {
    color: @asphalt-7;
}
.ant-pagination .ant-pagination-total-text {
    font-size: 16px;
}
.ant-form-item-label,
.ant-radio-group,
.ant-pagination-item,
.ant-btn,
.ant-breadcrumb {
    font-weight: 600;
}
.ant-form-item-label {
    line-height: 1.2;
    padding-bottom: 5px;
}
.ant-form-item-label > label {
    color: @gray-8;
}

// Mobile filters menu
@media (max-width: 1023px) {
    .ant-menu-title-content {
        overflow: initial !important;
    }
    .ant-menu-submenu-title {
        height: auto !important;
        display: block !important;
    }
    .ant-menu-item {
        height: auto !important;
    }
}

.ant-row-flex,
.ant-row {
    margin-left: -8px;
    margin-right: -8px;
    &.reset-margin {
        margin-left: 0px;
        margin-right: 0px;
    }
    .ant-col {
        &.reset-padding {
            padding-left: 0px;
            padding-right: 0px;
        }
        padding-left: 8px;
        padding-right: 8px;
    }
}
.ant-form-item .ant-row .ant-col.ant-form-item-label {
    padding-left: 8px;
    padding-right: 8px;
}
.ant-list-grid {
    margin-left: -8px;
    margin-right: -8px;
}
.ant-select,
.ant-input {
    font-weight: 600;
}
.ant-radio-group {
    .anticon {
        margin: 0 5px;
    }
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    background: @cement;
}

.list {
    &.horizontal {
        margin: -5px;
        .ant-list-item,
        .item {
            display: inline-flex;
            padding: 5px;
        }
        .divider {
            border-left: 1px solid @gray-4;
        }
    }
    &.list-advice {
        font-size: 16px;
        font-weight: 600;
        margin: 0 -10px;
        @media (max-width: 767px) {
            font-size: 14px;
        }
        .ant-list-item,
        .item {
            padding: 0 10px;
            &:hover {
                color: @asphalt-8;
            }
        }
        .anticon {
            font-size: 20px;
            @media (max-width: 767px) {
                font-size: 18px;
            }
        }
    }
    .ant-dropdown-link {
        font-size: 16px;
        @media (max-width: 767px) {
            font-size: 14px;
        }
    }
    &.list-lot {
        font-size: 16px;
    }
    .ant-tag {
        margin: 0;
    }
}
.ant-affix {
    z-index: 1001;
    .anchor-nav {
        background: #fff;
    }
}

.ant-dropdown-link {
    font-size: 16px;
    font-weight: 600;
}

.badge-item {
    .ant-badge-count {
        background-color: #fff;
        color: #595959;
        position: absolute;
        top: 100%;
        font-weight: 600;
        overflow: hidden;
    }
}
.ant-tag {
    border: none;
    .anticon {
        margin: 0 5px 0 0;
    }
    .emoji-mart-emoji {
        font-size: 12px;
        line-height: 1.2;
        vertical-align: middle;
        display: inline-block;
        margin-right: 1px;
    }
}
.ant-tabs {
    .section-tool {
        padding: 15px 0 0;
    }
    .ant-tabs-ink-bar {
        background: @m-green-6;
    }
}
.ant-tabs-nav {
    font-weight: 600;
}
.ant-tabs {
    .section-filter,
    .section-tool {
        padding-top: 0;
    }
}
.orange {
    color: @orange-6 !important;
}
.gray {
    color: #bfbfbf;
}

.ant-tabs {
    overflow: visible;
    .ant-tabs-nav .ant-tabs-tab-active {
        color: @asphalt-7;
        .ant-tabs-tab-btn {
            text-shadow: none;
        }
    }
}

.anticon {
    &.disabled {
        color: @gray-4;
        transition: color 0.3s;
    }
}

.ant-input-password-icon {
    color: @gray-8;
    &.anticon-eye {
        color: @blue-6;
    }
}

.ant-alert-warning {
    background-color: rgba(@gold-1, 0.5);
    border: 1px solid rgba(@gold-3, 0.5);
}
.ant-timeline {
    .ant-timeline-item-head {
        width: 8px;
        height: 8px;
    }
    .ant-timeline-item-tail {
        position: absolute;
        top: 12px;
        left: 3px;
        height: calc(100% - 16px);
        border-left: 2px solid #e8e8e8;
    }
    .ant-timeline-item {
        padding: 0 0 15px;
    }
    .ant-timeline-item-content {
        top: -8px;
    }
    .ant-timeline-item {
        font-size: 16px;
        color: @gray-8;
    }
    em {
        font-size: 14px;
    }
    .timeline-item-last .ant-timeline-item-content {
        &:before {
            content: '';
            width: 100%;
            height: 30px;
            position: absolute;
            bottom: 25px;
            left: 0;
            right: 0;
            background: linear-gradient(180deg, rgba(251, 252, 254, 0) 0%, #fbfcfe 100%);
        }
    }
    .timeline-item-pending {
        .ant-timeline-item-head {
            border-color: @gray-6;
        }
        .ant-timeline-item-content {
            color: @gray-6;
        }
        .ant-timeline-item-head-custom {
            color: @gray-6;
        }
    }
    .ant-timeline-item-head-custom {
        top: -2px;
        left: 0px;
        background: none;
        font-size: 14px;
    }
    .timeline-item-outbid {
        .ant-timeline-item-head {
            border-color: @red-6;
        }
        .ant-timeline-item-content {
            color: @red-6;
        }
    }
    .timeline-item-mine {
        .ant-timeline-item-head {
            border-color: @asphalt-5;
        }
        .ant-timeline-item-content {
            color: @asphalt-5;
        }
    }
}
.pagination-hide-numbers {
    .ant-pagination-jump-next,
    .ant-pagination-jump-prev,
    .ant-pagination-total-text,
    .ant-pagination-item,
    .ant-pagination-options {
        display: none;
    }
}
.pagination-show-total {
    .ant-pagination-jump-next,
    .ant-pagination-jump-prev,
    .ant-pagination-item,
    .ant-pagination-options {
        display: none;
    }
}
.ant-typography {
    .emoji-mart-emoji {
        margin-right: 5px;
    }
    &.disabled {
        color: @gray-8;
    }
    &.title-green {
        color: @asphalt-6 !important;
    }
    &.title-darkgreen {
        color: @asphalt-7 !important;
    }
}
.ant-progress {
    &.blue .ant-progress-inner {
        background-color: @blue-7;
    }
    &.orange .ant-progress-inner {
        background-color: @orange-6;
    }
    &.volcano .ant-progress-inner {
        background-color: @volcano-6;
    }
    &.red .ant-progress-inner {
        background-color: @red-6;
    }
    &.gray .ant-progress-inner {
        background-color: rgba(0, 0, 0, 0.1);
    }
    &.green .ant-progress-inner {
        background-color: @asphalt-5;
    }
}

.ant-btn-background-ghost {
    .anticon {
        color: @gray-8;
    }
    &:disabled {
        .anticon {
            color: rgba(0, 0, 0, 0.25);
        }
    }
}
.list-ico.horizontal {
    margin: 0 -10px;
    font-size: 16px;
    .ant-list-item,
    .item {
        padding: 0 10px;
        a {
            color: @asphalt-6;
        }
    }
}
.btn-heart {
    color: @asphalt-5;
    border: none;
    box-shadow: none;
    font-size: 18px;
    background: none;
    border-radius: 50% !important;
    &.active {
        background-color: rgba(@asphalt-1, 0.5);
        color: @asphalt-6 !important;
    }
    &.no-circle {
        color: @asphalt-6;
        &.active {
            background: none;
        }
    }
    &:focus,
    &.active,
    &:hover {
        background: none;
    }
    &.btn-visual-heart {
        background-color: rgba(#000, 0.5);
        &:hover {
            background-color: @asphalt-6;
            color: #fff !important;
        }
    }
}

.ant-calendar-picker-icon {
    color: @gray-8;
}
.ant-breadcrumb {
    .anticon {
        font-size: 12px;
    }
}

.ant-table {
    width: 100%;
    // overflow-y: auto;
    _overflow: auto;
}

.ant-radio-group-large .ant-radio-button-wrapper {
    @media (max-width: 1023px) {
        height: 32px;
        font-size: 14px;
        line-height: 32px;
    }
}
.ant-select-lg .ant-select-selection__rendered {
    @media (max-width: 1023px) {
        line-height: 30px;
    }
}
.ant-btn-circle-outline.ant-btn-lg,
.ant-btn-circle.ant-btn-lg {
    @media (max-width: 1023px) {
        min-width: 32px;
    }
}
.ant-input--error {
    border-color: #f5222d;
}
.ant-collapse {
    border: none;
    overflow: hidden;
    > .ant-collapse-item {
        background-color: #fff;
        border-width: 1px 1px 0;
        border-style: solid;
        border-color: @gray-4;
        position: relative;
        &:last-child {
            border-width: 1px;
        }
        .ant-collapse-header {
            font-weight: 600;
        }
        &.ant-collapse-item-active {
            background: rgba(230, 255, 251, 0.5);
            border-color: @asphalt-2;
            .ant-collapse-header {
                color: @asphalt-8;
                background: @m-green-1;
            }
            .ant-collapse-content {
                border-color: @asphalt-2;
            }
            &:before {
                content: '';
                position: absolute;
                width: 100%;
                height: 1px;
                bottom: -1px;
                background-color: @asphalt-2;
                z-index: 10;
            }
        }
    }
}
.collapse-info {
    .ant-collapse-content-box {
        padding: 30px 40px;
        @media (max-width: 1399px) {
            padding: 30px;
        }
        @media (max-width: 1023px) {
            padding: 30px 15px;
        }
    }
    &.collapse-info-add {
        .ant-collapse-content-box {
            padding: 30px 100px;
            @media (max-width: 1399px) {
                padding: 30px;
            }
            @media (max-width: 1023px) {
                padding: 30px 15px;
            }
        }
    }
}
.collapse-add {
    .ant-collapse-arrow {
        display: none !important;
    }
    .ant-collapse-header {
        padding: 0 !important;
    }
    .ant-btn {
        display: block;
        text-align: left;
        padding: 12px 15px;
        height: auto;
        width: 100%;
        color: @gray-7;
    }
}
.ant-form-item-control {
    line-height: inherit;
}
.ant-upload-list-item {
    margin-top: 0;
    .ant-upload-list-item-name {
        color: @gray-8;
    }
}

.menu-item-divider {
    &.ant-dropdown-menu-item {
        margin: 0;
        padding: 0;
        height: 2px;
        background-color: rgba(0, 0, 0, 0.6);
    }
}

.ant-back-top-content {
    background-color: rgba(54, 207, 201, 0.7);
    &:hover {
        background-color: rgba(54, 207, 201, 0.9);
    }
}

.ant-menu-root.menu-filter {
    font-weight: 600;
    border-right: none;

    .ant-menu-submenu {
        border-bottom: 1px solid #e8e8e8;
    }

    .ant-menu-item-selected {
        background: none;

        &:after {
            border: none;
        }
    }

    .ant-menu-item-group-title {
        padding-left: 24px;
        font-size: 12px;
    }

    .submenu-full {
        .ant-menu-item {
            padding-left: 15px !important;
            height: auto;
            line-height: 1.2;

            &.vehicle-filter-item {
                padding-left: 48px !important;
                line-height: 30px;
            }
        }

        .ant-form-item {
            margin-bottom: 12px;
        }

        .ant-menu-sub {
            padding-bottom: 15px;
        }
    }

    .ant-select-arrow .anticon {
        margin-right: 0;
    }

    .ant-select {
        width: 100%;
    }
}

.ant-menu-root.menu-filter .submenu-full .ant-menu-sub {
    &:last-child {
        padding-bottom: 0;
    }
}

.ant-menu-root.menu-filter .submenu-full .ant-menu-sub {
    &:last-child {
        padding-bottom: 0;
    }
}

.ant-drawer-mask,
.ant-modal-mask {
    background: rgba(255, 255, 255, 0.9);
}

.ant-alert.alert-box {
    padding: 15px 15px 5px;
    &.ant-alert-warning {
        background-color: @gold-1;
        border-color: @gold-3;
    }
    .ant-alert {
        border: none;
        background: none;
        margin: 1em 0 0;
        padding: 8px 15px 8px 25px;
    }
    .ant-alert-icon {
        left: 0;
    }
    .ant-typography {
        margin: 0;
        padding-bottom: 1em;
    }
}

.ant-alert.alert-dark {
    &.ant-alert-warning {
        background-color: @gold-1;
        border-color: @gold-3;
    }
}

.ant-modal.full-witdh-footer {
    .ant-modal-footer {
        padding: 10px 0;
    }
}
