@import '@joyrideautos/web-common-components/src/styles/constants.less';

.banner {
    padding: 22px 50px;
    background-color: @orange-1;
    border-top: 1px solid @orange-4;

    display: flex;
    justify-content: space-between;
    align-items: center;

    &.expired {
        background-color: #FFF2F0;
        border-top: 1px solid @red-2;

        .footer-icon{
            color: @red-5;
        }
    }

    .banner-btn {
        :global(.ant-btn.ant-btn-default) {
            background-color: transparent;
        }
    }

    .footer-icon {
        color: @orange-4;
        margin-right: 12px;
        font-size: 20px;
    }

    .centered-icon-text {
        display: flex;
        align-items: center;
    }

    .main-title {
        font-size: 16px;
        font-weight: 500;
    }

    .sub-title {
        color: @gray-8;
        padding-top: 8px;
    }
}

@media (max-width: 768px) {
    .banner {
        flex-direction: column;

        .banner-btn {
            margin-top: 10px;
        }
    }
}
