@import '@joyrideautos/web-common-components/src/styles/constants.less';
@import '../../styles/constants.less';

@transition-color: {
    color: @m-green-6;
    transition: color 0.3s ease;
};

@green-color: {
    background-color: rgba(0, 168, 93, 0.15) !important;
    border-bottom-color: @m-green-6;

    &.ant-menu-item.ant-menu-item-selected:after {
        border-bottom-color: @m-green-6;
    }

    &.ant-menu-item:hover {
        a,
        .anticon {
            @transition-color();
        }

        &:after {
            border-bottom-color: @m-green-6;
        }
    }

    &.ant-menu-submenu {
        &:after,
        &:hover::after {
            border-bottom-color: @m-green-6;
        }

        .ant-menu-submenu-title {
            span {
                @transition-color();
            }
        }
    }
};

.header-affix .header-wrapper {
    background-color: white;
    box-shadow: 0 2px 4px rgb(0 0 0 / 4%);
}

.main-header.container {
    max-width: 1430px;
}

.main-header {
    display: flex;
    align-items: center;
    height: @HEADER_HEIGHT;

    .logo {
        padding-right: 20px;
        flex: 0 0 auto;
    }

    .btn-mobile-menu,
    .mobile-header-menu {
        display: none;
    }

    .anticon-bell {
        color: #435a73;
        font-size: 18px !important;
    }

    li {
        &.ant-menu-item-selected,
        &.ant-menu-submenu-selected,
        &.ant-menu-submenu-open,
        &:hover {
            @green-color();
        }
    }

    .ant-menu-horizontal {
        .ant-menu-item::after,
        .ant-menu-submenu::after {
            left: 0;
            right: 0;
        }
    }

    &.is-mobile {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-areas: 'toggleMenu logo menu';
        justify-content: center;

        .btn-mobile-menu {
            display: flex;
            grid-area: toggleMenu;
            justify-content: flex-start;
        }

        .logo {
            display: flex;
            padding: 0;
            grid-area: logo;
            justify-content: center;
        }

        .ant-menu {
            display: flex;
            grid-area: menu;
            justify-content: flex-end;

            .ant-menu-overflow-item-rest {
                display: none;
            }

            .ant-menu-item {
                margin: 0;
                padding: 0 3px;

                &.ant-menu-item-selected {
                    background-color: transparent !important;
                }

                .anticon {
                    font-size: 18px;
                    color: #435a73;
                }
            }
        }

        &.is-anonymous {
            grid-template-columns: 1fr 1fr;
            grid-template-areas: 'anon-logo anon-menu';

            .logo {
                grid-area: anon-logo;
                justify-content: flex-start;
            }

            .ant-menu {
                grid-area: anon-menu;
                justify-content: space-between;
            }
        }

        .ant-menu-horizontal {
            .ant-menu-item::after,
            .ant-menu-submenu::after {
                display: none;
            }
        }

        li.ant-menu-item-active:has(.anticon-bell) {
            background-color: transparent !important;
        }

        .ant-menu-item-selected::after {
            border-bottom: none !important;
        }

        .ant-menu-item {
            padding: 0;
        }

        .ant-menu-item:has(.search) {
            .ant-menu-title-content {
                margin: 0;
            }
        }

        .btn-mobile-menu {
            color: #435a73;
            font-size: 18px;
            padding-left: 10px;

            em {
                font-size: 14px;
                color: #435a73;
                font-style: normal;
                padding: 0 0 0 7px;
            }

            .open-item {
                display: flex;
            }

            .close-item {
                display: none;
            }
        }
    }

    .ant-menu-horizontal {
        line-height: @HEADER_HEIGHT;
        width: 100%;
        border-bottom: none;
    }

    .ant-menu-item,
    .ant-menu-submenu {
        font-size: 16px;
        font-weight: 600;
    }

    .ant-badge {
        .ant-badge-count {
            background-color: @asphalt-7;
        }

        &.notification-badge {
            .ant-badge-count {
                position: absolute !important;
                top: 5px;
                right: 12px;
            }
        }

        &.my-vehicles-badge {
            .ant-badge-count {
                margin-left: 5px;
            }
        }
    }

    @media (max-width: 1140px) {
        .search,
        .help {
            span {
                display: none;
            }
        }
    }
}

.header-menu-current-seller-name {
    .anticon {
        margin-inline-end: 10px;
    }

    span {
        color: rgba(0, 0, 0, 0.65);
    }
}

.menu-open {
    .main-header {
        &.is-mobile {
            .open-item {
                display: none;
            }

            .close-item {
                display: flex;
            }
        }
    }
}

.ant-drawer {
    &.mobile-drawer {
        top: @HEADER_HEIGHT;

        &.with-top-view-mobile {
            top: @HEADER_HEIGHT + @IMPERSONATE_WARNING_HEIGHT_MOBILE;
        }

        .ant-badge {
            .ant-badge-count {
                background-color: @asphalt-7;
                margin-left: 5px;
            }
        }

        .ant-drawer-content-wrapper {
            height: 100% !important;
            box-shadow: none;
        }

        .ant-drawer-content-wrapper {
            height: 100%;
        }

        .ant-drawer-body {
            padding: 0;
        }

        .ant-menu-submenu-title,
        .ant-menu-item {
            font-size: 18px;
            font-weight: 600;
        }
    }
}

.drop-notifications-container {
    width: 385px;
    padding: 0;
    margin-top: -2px;
    overflow: hidden;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    @media (max-width: 375px) {
        width: 100vw;
    }

    .ant-dropdown-menu .ant-dropdown-menu-item {
        padding: 0;
        cursor: default;

        &:hover {
            background: none;
        }
    }

    .ant-dropdown-menu-item.ant-dropdown-menu-item-active {
        background-color: white;
        padding: 0 !important;
    }
}

.drop-notifications-item {
    position: relative;

    .notification-progress {
        position: absolute;
        left: 0;
        bottom: 0;
        line-height: 0;
        width: 100%;
    }
}

.mobile-drawer {
    .ant-menu-sub.ant-menu-inline {
        background-color: white;
    }
}
