// CSS helpers

.m-50 {
    margin: 50px;
}
.m-30 {
    margin: 30px;
}
.m-20 {
    margin: 20px;
}
.m-15 {
    margin: 15px;
}
.m-10 {
    margin: 10px;
}

.mt-50 {
    margin-top: 50px;
}
.mt-30 {
    margin-top: 30px;
}
.mt-15 {
    margin-top: 15px;
}
.mt-20 {
    margin-top: 20px;
}
.mt-10 {
    &.reset-margin {
        margin-top: 10px;
    }
    margin-top: 10px;
}
.mt--10 {
    margin-top: -10px;
}
.mt-8 {
    margin-top: 8px;
}

.mb-50 {
    margin-bottom: 50px;
}
.mb-30 {
    margin-bottom: 30px;
}
.mb-15 {
    margin-bottom: 15px;
}
.mb-16 {
    margin-bottom: 16px;
}
.mb-20 {
    margin-bottom: 20px;
}
.mb-24 {
    margin-bottom: 24px;
}
.mb-10 {
    &.reset-margin {
        margin-bottom: 10px;
    }
    margin-bottom: 10px;
}
.mb-8 {
    margin-bottom: 8px;
}
.mb-0 {
    margin-bottom: 0;
}

.mr-50 {
    margin-right: 50px;
}
.mr-30 {
    margin-right: 30px;
}
.mr-25 {
    margin-right: 25px;
}
.mr-20 {
    margin-right: 20px;
}
.mr-15 {
    margin-right: 15px;
}
.mr-10 {
    margin-right: 10px;
}
.mr-5 {
    margin-right: 5px;
}
.mr-0 {
    margin-right: 0px;
}

.ml-50 {
    margin-left: 50px;
}
.ml-30 {
    margin-left: 30px;
}
.ml-25 {
    margin-left: 25px;
}
.ml-20 {
    margin-left: 20px;
}
.ml-15 {
    margin-left: 15px;
}
.ml-10 {
    margin-left: 10px;
}
.ml-5 {
    margin-left: 5px;
}
.ml-0 {
    margin-left: 0px;
}
.ml--5 {
    margin-left: -5px;
}

.p-50 {
    padding: 50px;
}
.p-30 {
    padding: 30px;
}
.p-20 {
    padding: 20px;
}
.p-15 {
    padding: 15px;
}
.p-10 {
    padding: 10px;
}

.pt-50 {
    padding-top: 50px;
}
.pt-30 {
    padding-top: 30px;
}
.pt-15 {
    padding-top: 15px;
}
.pt-10 {
    padding-top: 10px;
}
.pt-0 {
    padding-top: 0;
}

.pb-50 {
    padding-bottom: 50px;
}
.pb-30 {
    padding-bottom: 30px;
}
.pb-20 {
    padding-bottom: 15px;
}
.pb-15 {
    padding-bottom: 15px;
}
.pb-10 {
    padding-bottom: 10px;
}
.pb-0 {
    padding-bottom: 0;
}

.pl-50 {
    padding-left: 50px;
}
.pl-30 {
    padding-left: 30px;
}
.pl-20 {
    padding-left: 20px;
}
.pl-15 {
    padding-left: 15px;
}
.pl-10 {
    padding-left: 10px;
}
.pl-0 {
    padding-left: 0;
}

.pr-50 {
    padding-right: 50px;
}
.pr-30 {
    padding-right: 30px;
}
.pr-20 {
    padding-right: 20px;
}
.pr-15 {
    padding-right: 15px;
}
.pr-10 {
    padding-right: 10px;
}
.pr-0 {
    padding-right: 0;
}

.reset-padding {
    padding: 0;
}
.reset-margin {
    margin: 0;
}

.fs-13 {
    font-size: 13px;
}

.full-width-height {
    width: 100%;
    height: 100%;
}

.xs-block {
    @media (max-width: 576px) {
        display: block;
    }
}

.bg-light {
    background-color: #fbfcfe;
}
.background-white {
    background-color: @gray-1;
}

.hidden-el {
    opacity: 0;
}

.text-center {
    text-align: center;
}
.text-right {
    text-align: right !important;
}
.text-left {
    text-align: left;
}
.text-capitalize {
    text-transform: capitalize;
}
.text-uppercase {
    text-transform: uppercase;
}
.text-strong {
    font-weight: 600;
}
.text-white {
    color: #fff;
}

.float-right {
    float: right;
}

.not-active {
    color: @gray-4;
}

.title-green {
    color: @asphalt-7;
}

.ttl-size-sm {
    font-size: 16px;
}

.reset-font-size {
    font-size: 0 !important;
}

.truncate-parent {
    display: flex;
    align-items: center;
}

.truncate {
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.truncate-100 {
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.flex-column {
    display: flex;
    flex-direction: column;
}

.grid-3-items-per-row {
    display: grid;
    grid-gap: 10px;
    @media (min-width: 639px) {
        grid-template-columns: repeat(1, 1fr);
    }
    @media (min-width: 767px) {
        grid-template-columns: repeat(2, 1fr);
    }
    @media (min-width: 1023px) {
        grid-template-columns: repeat(3, 1fr);
    }
}

.show-desktop {
    @media (max-width: 767px) {
        display: none;
    }
}
.show-mobile {
    display: none;
    @media (max-width: 767px) {
        display: block;
    }
}
.show-md-desktop {
    @media (max-width: 991px) {
        display: none;
    }
}
.show-md-mobile {
    display: none;
    @media (max-width: 991px) {
        display: block;
        &.inline-b {
            display: inline-block !important;
        }
    }
}

.width-100 {
    width: 100%;
}
