@import '@joyrideautos/web-common-components/src/styles/constants.less';
@import '../../styles/constants.less';

.impersonate-container {
    background-color: @orange-5;
    height: @IMPERSONATE_WARNING_HEIGHT;
    @media (max-width: 768px) {
        height: @IMPERSONATE_WARNING_HEIGHT_MOBILE;
        padding-top: 8px;
        padding-bottom: 8px;
        line-height: 1.6;
    }

    :global(.ant-row) {
        margin: 0 auto;
        @media (max-width: 768px) {
            margin: 0;
            padding: 0;
        }
        max-width: 1430px;
        height: 100%;
    }
}

.impersonate-user {
    padding-left: 15px;
    @media (max-width: 768px) {
        padding-left: 20px;
    }

    :global(.ant-col) {
        &.impersonate-user-box {
            display: flex;
            align-items: center;
            p {
                margin: 0;
            }
            @media (max-width: 768px) {
                padding-left: 8px;
                font-size: medium;
            }
        }

        :global(.anticon) {
            padding-right: 10px;
        }
    }
}

.impersonate-logout {
    padding-right: 15px;
    @media (max-width: 768px) {
        padding-right: inherit;
    }

    :global(.ant-col) {
        :global(.ant-btn) {
            background-color: @asphalt-6;
        }
    }
}
