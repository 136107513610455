.modal-pdf {
    width: 815px !important;
    iframe {
        background-color: #fff;
        position: relative;
        z-index: 10;
    }
}

.top-modal-tools {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    padding: 25px;
    margin: 0;
    @media (max-width: 768px) {
        padding: 15px;
    }
    .ant-typography {
        font-size: 24px;
        color: #fff;
        padding: 0 30px;
        margin: 0;
        @media (max-width: 768px) {
            font-size: 16px;
            padding: 0 10px;
        }
    }
    .ant-col {
        display: flex;
        align-items: center;
    }
    .text-right {
        justify-content: flex-end;
    }
}

.file-name {
    color: #fff;
}