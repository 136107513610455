@import '@joyrideautos/web-common-components/src/styles/constants.less';

.message-top {
    width: 100%;
    text-align: center;
    overflow: hidden;
    z-index: 99;
    .ant-alert {
        justify-content: center;
        border: none;
        border-radius: 0;
        padding: 8px 15px;
        line-height: 25px;
        a {
            color: @blue-6;
        }
        strong {
            font-weight: 600;
        }
        &.alert-live {
            background: @asphalt-6;
            font-size: 16px;
            @media (max-width: 1023px) {
                font-size: 14px;
            }
            .ant-alert-message {
                color: #fff;
                a {
                    color: #fff;
                }
            }
            .ant-alert-icon {
                color: #fff;
            }
            .ant-tag {
                color: #fff !important;
                border: none;
                margin-left: 5px;
                font-size: 16px;
                line-height: 1.4;
                @media (max-width: 1023px) {
                    font-size: 14px;
                }
                &.blue {
                    background-color: @blue-5;
                }
                &.orange {
                    background-color: @orange-6;
                }
                &.volcano {
                    background-color: @volcano-6;
                }
                &.red {
                    background-color: @red-6;
                }
                &:hover {
                    opacity: 1;
                }
            }
        }
        &.alert-fail {
            background: @red-6;
            .ant-alert-message {
                color: #fff;
                a {
                    color: #fff;
                }
            }
            .ant-alert-icon {
                color: #fff;
            }
            .ant-btn {
                &.ant-btn-dangerous {
                    background-color: @red-5;
                    color: @white;
                    border-color: @red-5;
                }
                &:hover,
                &:focus {
                    background-color: @red-4;
                    border-color: @red-4;
                }
                &:active,
                &.active {
                    background-color: @red-7;
                    border-color: @red-7;
                }
            }
        }
        &.alert-completed {
            background-color: #f1f2f4;
            .ant-alert-message {
                color: #595959;
            }
            .ant-alert-icon {
                color: @asphalt-6;
            }
        }
        .ant-alert-content {
            flex: inherit;
        }
    }
    .ant-alert-icon {
        position: relative;
        top: inherit;
        left: inherit;
        margin-right: 5px;
    }
    .ant-progress {
        display: block;
        .ant-progress-inner {
            vertical-align: top;
            border-radius: 0;
        }
        .ant-progress-success-bg,
        .ant-progress-bg {
            background-color: #f5f5f5;
            border-radius: 0;
        }
    }
}