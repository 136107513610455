@import 'antd/dist/reset.css';
@import './constants.less';
@import './helpers.less';
@import './theme.less';

img {
    max-width: 100%;
    height: auto;
}

body {
    min-height: 100vh;
    font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB',
        'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
        'Segoe UI Symbol';
    background-color: #fbfcfe;
}

body,
html {
    height: 100%;
}

.landing-page {
    background: #fff;

    .main {
        padding: 0;
    }
}

.container {
    max-width: 1230px;
    margin: 0 auto;
    padding-right: 15px;
    padding-left: 15px;

    &.container-full {
        max-width: 100%;
    }

    &.container-inventory-page {
        max-width: @container-width;
    }
}

.payment-result-container {
    min-height: 30em;
    i.payment-success {
        font-size: 5em;
        color: #08979c;
    }
    i.payment-failed {
        font-size: 5em;
        color: @red-5;
    }
    h2,
    h3,
    h4 {
        margin-bottom: 0;
    }
    .ant-row-flex {
        min-height: 6em;
    }
}

#root {
    width: 100%;
    display: flex;
    height: 100%;
}

.App {
    width: 100%;
    display: table;
    width: 100%;
    height: 100%;
    table-layout: fixed;
}

.wrapper {
    width: 100%;
    position: relative;
    display: table-cell;
    background: none;
}

.main {
    padding-top: 30px;
    padding-bottom: 50px;

    &:has(.checkout-header) {
        padding-top: 0;
    }
}

.card-box {
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.02), 0px 32px 84px rgba(0, 0, 0, 0.08);
    border-radius: 12px;
    border: 1px solid #f5f5f5;
    text-align: center;
    margin: 0 0 35px;
    &.card-box-default {
        border-radius: 0;
        border: none;
        box-shadow: none;
        margin: 0;
        .card-holder {
            margin-bottom: 20px;
        }
        .card-bottom {
            padding: 0 0 25px;
        }
    }
    .ant-card-body {
        padding: 0;
    }
    .ant-dropdown {
        left: 20px !important;
        right: 20px !important;
    }
    .card-top {
        padding: 30px 30px 20px;
        border-bottom: 1px solid @gray-4;
        position: relative;
        .ant-alert {
            max-width: 180px;
            margin: 0 auto 10px;
        }
    }
    .ant-card-meta {
        margin-bottom: 15px;
    }
    .ant-alert-message a {
        font-weight: 600;
    }
    .progress-card {
        position: absolute;
        bottom: -1px;
        width: 100%;
        height: 6px;
        left: 0;
        .ant-progress-outer,
        .ant-progress {
            display: block;
        }
        .ant-progress-inner {
            background-color: @blue-6;
            display: block;
            .ant-progress-bg {
                background-color: #e2e3e5;
            }
        }
        &.orange .ant-progress-inner {
            background-color: @orange-6;
        }
        &.volcano .ant-progress-inner {
            background-color: @volcano-6;
        }
        &.red .ant-progress-inner {
            background-color: @red-6;
        }
        &.gray .ant-progress-inner {
            background-color: rgba(0, 0, 0, 0.1);
        }
        &.green .ant-progress-inner {
            background-color: @asphalt-5;
        }
    }
    .ant-progress-inner,
    .ant-progress-bg {
        border-radius: 0;
    }
    .card-radio {
        margin-bottom: 15px;
        .ant-radio-button-wrapper-disabled {
            background: #fff;
            &.ant-radio-button-wrapper-checked {
                background-color: #e6e6e6;
            }
        }
    }
    .card-number {
        .ant-input-number {
            display: block;
        }
        .amount-validation-message,
        .ant-typography {
            display: block;
            max-width: 150px;
            margin: 0 auto;
            min-height: 35px;
        }
    }
    .ant-alert {
        text-align: left;
    }
    .card-holder {
        max-width: 255px;
        margin: 0 auto 30px;
        .ant-btn {
            width: 100%;
            border-radius: 4px;
            span {
                padding: 0 2px;
            }
        }
    }
    .card-frame {
        padding: 24px 16px 16px;
    }
    .head {
        .ant-alert {
            display: inline-block;
        }
    }
    .btn-bid {
        width: 100%;
        height: 40px;
        font-size: 16px;
        &:disabled {
            border-color: transparent;
        }
    }
    .card-remaining {
        font-size: 16px;
        font-weight: 600;
        color: @gray-7;
        margin: 10px 0 5px;
        &.blue {
            color: @blue-6;
        }
        &.orange {
            color: @orange-6;
        }
        &.volcano {
            color: @volcano-6;
        }
        &.red {
            color: @red-6;
        }
        &.gray {
            color: rgba(0, 0, 0, 0.1);
        }
        &.default {
            color: @gray-8;
        }
        .anticon {
            margin: 0 5px 0 0;
            font-size: 14px;
        }
    }
    .start-bid {
        color: @gray-8;
        cursor: pointer;
        display: flex;
        justify-content: center;
        .emoji-mart-emoji,
        .anticon-close-circle {
            padding: 0 3px;
        }
        > span {
            display: inline-flex;
        }
        em,
        a {
            font-weight: 600;
            color: @gray-6;
            font-style: normal;
            pointer-events: none;
        }
        &.current {
            em,
            a {
                color: @asphalt-7;
                pointer-events: painted;
            }
        }
        &.success {
            color: @m-green-8;
        }
        &.error {
            color: @red-6;
        }
        em {
            font-style: normal;
        }
        .ant-dropdown-trigger {
            a {
                margin-left: 2px;
            }
        }
    }
    .bid-amount {
        font-size: 56px;
        line-height: 1.2;
        font-weight: normal;
        &.start {
            color: @gray-6;
        }
        &.current {
            color: @gray-9;
        }
        &.outbid {
            color: @red-7;
        }
        sup {
            font-size: 0.5em;
            font-weight: 500;
        }
    }
    .ant-alert-info {
        background-color: #f3fbff;
        border: 1px solid @blue-3;
        h4 {
            font-size: 14px;
            margin-bottom: 0;
            color: @gray-8;
        }
    }
    .ant-alert-error {
        background: rgba(255, 241, 240, 0.5);
        border: 1px solid #ffa39e;
    }
}
.card-number {
    margin-bottom: 20px;
    .ant-input-number {
        max-width: 255px;
        margin: 0 auto 10px;
        border: none;
        text-align: center;
        font-size: 38px;
        color: @asphalt-7;
        height: 45px;
        line-height: 1;
        &.ant-input-number-focused {
            box-shadow: none;
        }
        .anticon {
            display: none;
        }
        &.ant-input-number-disabled {
            background: none;
            .ant-input-number-handler-wrap {
                display: block;
            }
            .ant-input-number-handler-down {
                border-color: @gray-5;
                &:before {
                    background-color: @gray-6;
                }
            }
            .ant-input-number-handler-up {
                border-color: @gray-5;
                &:before {
                    background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.2969 3.5625H12.7031C12.8281 3.5625 12.8906 3.625 12.8906 3.75V20.25C12.8906 20.375 12.8281 20.4375 12.7031 20.4375H11.2969C11.1719 20.4375 11.1094 20.375 11.1094 20.25V3.75C11.1094 3.625 11.1719 3.5625 11.2969 3.5625Z' fill='%23BFBFBF'/%3E%3Cpath d='M4.125 11.1094H19.875C20 11.1094 20.0625 11.1719 20.0625 11.2969V12.7031C20.0625 12.8281 20 12.8906 19.875 12.8906H4.125C4 12.8906 3.9375 12.8281 3.9375 12.7031V11.2969C3.9375 11.1719 4 11.1094 4.125 11.1094Z' fill='%23BFBFBF'/%3E%3C/svg%3E%0A");
                }
            }
            .ant-input-number-input {
                color: @gray-6;
                -webkit-text-fill-color: @gray-6;
                -webkit-opacity: 1;
            }
        }
    }
    .ant-typography {
        font-size: 12px;
        color: #8c8c8c;
    }
    .ant-input-number {
        width: 255px;
        .ant-input-number-input {
            height: 100%;
            text-align: center;
            border: 1px solid transparent;
            border-radius: 8px;
            -webkit-appearance: none;
            vertical-align: middle;
            line-height: 40px;
            font-weight: normal;
            &:focus {
                box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
                border-color: @asphalt-6;
            }
        }
        .ant-input-number-input-wrap {
            padding: 0 45px;
        }
    }
    .ant-input-number-handler-wrap {
        opacity: 1;
        position: static;
        width: 100%;
        height: auto;
    }
    .ant-input-number-handler {
        width: 40px;
        height: 40px !important;
        position: absolute;
        top: 4px;
        border: 1px solid @asphalt-7;
        border-radius: 50%;
        &:hover {
            height: 40px !important;
            background-color: @asphalt-6;
            border-color: @asphalt-6;
            color: #fff;
            &.ant-input-number-handler {
                box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
            }
            &.ant-input-number-handler-down {
                &:before {
                    background-color: #fff;
                }
            }
            &.ant-input-number-handler-up {
                &:before {
                    background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.2969 3.5625H12.7031C12.8281 3.5625 12.8906 3.625 12.8906 3.75V20.25C12.8906 20.375 12.8281 20.4375 12.7031 20.4375H11.2969C11.1719 20.4375 11.1094 20.375 11.1094 20.25V3.75C11.1094 3.625 11.1719 3.5625 11.2969 3.5625Z' fill='%23fff'/%3E%3Cpath d='M4.125 11.1094H19.875C20 11.1094 20.0625 11.1719 20.0625 11.2969V12.7031C20.0625 12.8281 20 12.8906 19.875 12.8906H4.125C4 12.8906 3.9375 12.8281 3.9375 12.7031V11.2969C3.9375 11.1719 4 11.1094 4.125 11.1094Z' fill='%23fff'/%3E%3C/svg%3E");
                }
            }
        }
        &:active {
            background-color: @asphalt-7;
            border-color: @asphalt-7;
            box-shadow: none;
            &.ant-input-number-handler-down {
                &:before {
                    background-color: #fff;
                }
            }
            &.ant-input-number-handler-up {
                &:before {
                    background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.2969 3.5625H12.7031C12.8281 3.5625 12.8906 3.625 12.8906 3.75V20.25C12.8906 20.375 12.8281 20.4375 12.7031 20.4375H11.2969C11.1719 20.4375 11.1094 20.375 11.1094 20.25V3.75C11.1094 3.625 11.1719 3.5625 11.2969 3.5625Z' fill='%23fff'/%3E%3Cpath d='M4.125 11.1094H19.875C20 11.1094 20.0625 11.1719 20.0625 11.2969V12.7031C20.0625 12.8281 20 12.8906 19.875 12.8906H4.125C4 12.8906 3.9375 12.8281 3.9375 12.7031V11.2969C3.9375 11.1719 4 11.1094 4.125 11.1094Z' fill='%23fff'/%3E%3C/svg%3E");
                }
            }
        }
        &.ant-input-number-handler-down {
            left: 0;
            &:before {
                content: '';
                background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M20.4375 11.1094H3.5625C3.45937 11.1094 3.375 11.1937 3.375 11.2969V12.7031C3.375 12.8062 3.45937 12.8906 3.5625 12.8906H20.4375C20.5406 12.8906 20.625 12.8062 20.625 12.7031V11.2969C20.625 11.1937 20.5406 11.1094 20.4375 11.1094Z' fill='%23595959'/%3E%3C/svg%3E");
                position: absolute;
                top: 50%;
                left: 50%;
                width: 14px;
                height: 2px;
                margin: -1px 0 0 -7px;
                background-color: @asphalt-7;
            }
            &.ant-input-number-handler-down-disabled {
                border-color: @gray-5;
                background-color: #fff;
                box-shadow: none;
                &:before {
                    background-color: @gray-6;
                }
            }
        }
        &.ant-input-number-handler-up {
            right: 0;
            &:before {
                content: '';
                background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.2969 3.5625H12.7031C12.8281 3.5625 12.8906 3.625 12.8906 3.75V20.25C12.8906 20.375 12.8281 20.4375 12.7031 20.4375H11.2969C11.1719 20.4375 11.1094 20.375 11.1094 20.25V3.75C11.1094 3.625 11.1719 3.5625 11.2969 3.5625Z' fill='%2308979C'/%3E%3Cpath d='M4.125 11.1094H19.875C20 11.1094 20.0625 11.1719 20.0625 11.2969V12.7031C20.0625 12.8281 20 12.8906 19.875 12.8906H4.125C4 12.8906 3.9375 12.8281 3.9375 12.7031V11.2969C3.9375 11.1719 4 11.1094 4.125 11.1094Z' fill='%2308979C'/%3E%3C/svg%3E");
                position: absolute;
                top: 50%;
                left: 50%;
                width: 24px;
                height: 24px;
                margin: -12px 0 0 -12px;
            }
            &.ant-input-number-handler-up-disabled {
                border-color: @gray-5;
                background-color: #fff;
                box-shadow: none;
                &:before {
                    background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.2969 3.5625H12.7031C12.8281 3.5625 12.8906 3.625 12.8906 3.75V20.25C12.8906 20.375 12.8281 20.4375 12.7031 20.4375H11.2969C11.1719 20.4375 11.1094 20.375 11.1094 20.25V3.75C11.1094 3.625 11.1719 3.5625 11.2969 3.5625Z' fill='%23BFBFBF'/%3E%3Cpath d='M4.125 11.1094H19.875C20 11.1094 20.0625 11.1719 20.0625 11.2969V12.7031C20.0625 12.8281 20 12.8906 19.875 12.8906H4.125C4 12.8906 3.9375 12.8281 3.9375 12.7031V11.2969C3.9375 11.1719 4 11.1094 4.125 11.1094Z' fill='%23BFBFBF'/%3E%3C/svg%3E%0A");
                }
            }
        }
    }
    &.error {
        .ant-input-number-input {
            border: 1px solid #f5222d;
            &:focus {
                border-color: #f5222d;
            }
        }
        .ant-typography {
            color: @red-6;
        }
    }
    &.warning {
        .ant-input-number-input {
            border: 1px solid @orange-6;
            &:focus {
                border-color: @orange-6;
            }
        }
        .ant-typography {
            color: @orange-6;
        }
    }
    &.disabled {
        .ant-typography {
            color: @gray-6;
        }
    }
}
.collapse-breakdown {
    border: none;
    color: @asphalt-7 !important;
    box-shadow: none;
    background: none !important;
    &:hover {
        background: none;
    }
    &:hover {
        color: @asphalt-7;
    }
    &:disabled {
        background: none;
    }
}
.ant-alert-message {
    h4 {
        font-size: 14px;
        margin-bottom: 0;
        color: @gray-8;
    }
}
.fees-status {
    margin-top: 16px;
    .emoji-mart-emoji {
        vertical-align: middle;
    }
}
.card-bottom.card-bottom-old-design {
    padding: 0 16px 22px;
    & > .ant-alert {
        margin: 16px;
    }
    .fees-status.collapse-breakdown {
        @media (max-width: 567px) {
            display: block;
        }
    }
}
.card-box.cart-state {
    .card-holder {
        padding: 20px 30px;
    }
    .head {
        .emoji-mart-emoji {
            display: block;
            font-size: 60px;
            line-height: 1.2;
        }
        h1.ant-typography {
            font-size: 46px;
            margin: 0;
        }
    }
    .sum {
        display: block;
        font-size: 28px;
    }
    .gate-code-wrapper {
        margin-top: 16px;
        max-width: 164px;
        margin: 16px auto 0;
    }
    &.card-lost,
    &.card-pending,
    &.card-in-progress {
        background: radial-gradient(100% 98.6% at 0% 1.4%, #fafafa 0%, #e8e8e8 100%);
        box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
        border: 1px solid @gray-3;
        h1.ant-typography {
            color: @gray-6;
        }
        .card-frame {
            border-bottom: 1px solid transparent;
        }
    }
    &.card-in-progress {
        .head {
            h1.ant-typography {
                font-size: 40px;
            }
        }
        .card-holder {
            padding: 0;
        }
    }
    &.card-win {
        background: @asphalt-6;
        box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.02), 0px 32px 84px rgba(0, 0, 0, 0.08);
        color: #fff;
        h1.ant-typography {
            color: #fff;
        }
        a {
            color: #fff;
        }
        .list-description .ant-descriptions-item-content,
        .list-description .ant-descriptions-item-label {
            color: #fff;
        }
        .collapse-breakdown {
            color: #fff !important;
        }
        .ant-alert-message a {
            color: @asphalt-7;
        }
        .list-description .ant-descriptions-row {
            border-color: @asphalt-4;
        }
        button {
            margin-top: 10px;
        }
        .card-frame .ant-btn {
            background-color: @asphalt-5;
            color: @white;
        }
    }
    .state-bid {
        color: @asphalt-3;
        min-height: 40px;
        span {
            display: block;
            font-weight: 600;
        }
    }
    .error {
        color: @red-6;
    }
}
.tag-bid {
    background: none;
    border: none;
    color: @gray-9;
    font-size: 16px;
    font-weight: 600;
    margin: 0 0 15px;
    &.outbid {
        color: @red-7;
    }
    &:hover {
        opacity: 1;
    }
}

.bidding-timeline {
    padding: 0 10px 0 30px;
    .ant-dropdown-trigger {
        position: absolute;
        right: 0;
        top: 0;
        opacity: 0;
        &.ant-dropdown-open {
            opacity: 1 !important;
            background-color: @asphalt-6;
            color: #fff;
            border-color: @asphalt-6;
        }
    }
    .ant-timeline-item-content {
        padding: 0 35px 0 0;
    }
    .ant-timeline-item {
        &:hover {
            .ant-dropdown-trigger {
                opacity: 1;
            }
        }
    }
    .timestamp {
        display: inline-block;
        font-size: 13px;
    }
}

.bid-top-fixed,
.bidding-desktop-show {
    @media (max-width: 769px) {
        display: none;
    }
}

// global components
.tag-sold {
    border: 1px solid transparent;
    height: 24px;
    line-height: 24px;
    font-size: 14px;
    font-weight: 600;
    color: @green-7;
}

.tag-live {
    background-color: @blue-6;
    color: #fff;
    border: none;
    text-transform: uppercase;
    font-weight: 600;
    line-height: 24px;
}
.tag-unsold {
    color: @gray-7;
}
.tag-time_left {
    background: none;
    color: @blue-6;
    font-size: 14px;
    font-weight: 600;
    padding: 0;
    &.gray {
        color: @gray-7;
    }
    &.orange {
        color: @orange-6;
    }
    &.volcano {
        color: @volcano-6;
    }
    &.red {
        color: @red-6;
    }
}
.tag-winning {
    background: none;
    color: @gray-8;
    font-size: 14px;
    font-weight: 600;
    padding: 0;
}
.tag-outbid {
    background: none;
    color: @red-6;
    font-size: 14px;
    font-weight: 600;
    padding: 0;
}

.req-mark {
    display: block;
    color: @gray-7;
    padding: 10px 0;
    font-size: 13px;
    font-weight: 500;
}

.btn-cancel {
    background-color: @red-1;
    border-color: @red-1;
    color: @red-6;
    &:hover {
        background-color: @red-6;
        border-color: @red-6;
    }
    &:focus {
        background-color: @red-7;
        border-color: @red-7;
    }
}
.btn-more {
    color: @gray-8;
    font-size: 21px;
}
.btn-xsm {
    font-size: 12px;
}

.learn-more {
    padding: 0 18px 0 0;
    &:after {
        content: '→';
        position: absolute;
        top: 7px;
        right: 0;
        font-size: 18px;
        line-height: 1;
        font-weight: bold;
    }
}

.view-more {
    cursor: pointer;
    color: @asphalt-6;
    font-weight: 600;
}

.notification-error {
    width: fit-content;
    margin: 10px auto;
}

.scheduled {
    color: @asphalt-7;
    font-weight: bold;
    color: @green-6;
    padding: 2px;
    border-radius: 1px 2px;
    background-color: rgba(82, 196, 26, 0.1);
}

.description-divider {
    span {
        display: block;
    }
    .ant-divider {
        margin: 5px 0;
        background-color: #e8e8e8;
    }
}

.main-heading {
    display: flex;
    align-items: center;
    @media (max-width: 666px) {
        display: block;
    }

    .list {
        padding-left: 15px;
        @media (max-width: 666px) {
            padding: 5px 0 0;
        }
    }
}

.bidding-container {
    padding: 0 0 50px;
    @media (max-width: 1023px) {
        padding: 0;
    }

    .area-block {
        padding: 40px 30px;
        background-color: rgba(67, 90, 115, 0.04);
        border-radius: 24px;
        margin: 0 0 40px;
        @media (max-width: 767px) {
            padding: 30px 15px;
            order: 2;
        }
    }

    .bidding-aside {
        padding-left: 40px;
        margin: 0 0 30px;
        @media (max-width: 1025px) {
            padding-left: 10px;
        }
    }
}

.box-sale-info {
    margin: 0 0 15px;
}

.list-like {
    .ant-btn {
        padding: 0 12px;

        &.active {
            color: @asphalt-6;
            border-color: @asphalt-6;
        }
    }
}

.tab-full {
    width: 100vw;
    margin-left: calc((-100vw + 100%) / 2) !important;
    margin-right: calc((-100vw + 100%) / 2) !important;
    margin-bottom: 0 !important;
}

.circle-start {
    .ant-progress-circle-trail {
        fill: #fff;
        fill-opacity: 1 !important;
    }

    .ant-progress-text {
        font-size: 30px;
        color: #595959 !important;
        font-weight: 600;
    }
}

.gallery-block {
    margin-bottom: 50px;
    backface-visibility: hidden;
    transform: translateZ(0);

    .ant-typography {
        color: #fff;
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5) 0%, rgba(229, 229, 229, 0) 100%);
        padding: 15px 20px;
        text-shadow: 0 1px 2px rgba(0, 0, 0, 0.8);
        @media (max-width: 767px) {
            font-size: 21px;
        }
    }

    .swiper-slide > div {
        background-color: rgba(67, 90, 115, 0.04);
        background-repeat: no-repeat;
    }

    &.gallery-block-large {
        .gallery-top {
            height: 540px;
            @media (max-width: 767px) {
                height: 62vw;
            }
        }

        .gallery-thumbs .swiper-slide {
            height: 82px !important;
            @media (max-width: 667px) {
                height: 50px !important;
            }
        }

        .gallery-thumbs {
            margin: 0 100px;
            @media (max-width: 1023px) {
                margin: 0;
            }
        }
    }

    .swiper-slide {
        transform: translateZ(0);
        backface-visibility: hidden;
    }
}

.swiper-container.gallery-top {
    margin-bottom: 20px;
    border-radius: 12px;
    padding-top: 75%; // Aspect ratio 4:3
    height: 0;
    position: relative;
    .swiper-wrapper {
        position: absolute;
        top: 0;
    }

    .swiper-slide .gallery-img {
        width: 100% !important;
        background-size: cover;
        background-position: 50% 50%;
        border-radius: 12px;
        height: 100%;
    }

    .swiper-slide .gallery-container {
        width: 100% !important;
        background-size: cover;
        background-position: 50% 50%;
        border-radius: 12px;
        height: 100%;
        position: relative;
        display: none;
    }

    .swiper-slide-active .gallery-container,
    .swiper-slide-next .gallery-container {
        display: block !important;
    }

    .swiper-slide .gallery-title {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
    }
}

.stream-thumb {
    font-size: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: @orange-6;
    @media (max-width: 767px) {
        font-size: 24px;
    }
}

.gallery-video-icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);

    i {
        color: white;
        padding: 5px;
        font-size: 35px;
    }
}

.swiper-button-next,
.swiper-button-prev {
    width: 48px !important;
    height: 48px !important;
    background: rgba(0, 0, 0, 0.5);
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    border-radius: 50%;
    outline: none;

    &:hover {
        background: rgba(0, 168, 93, 0.8);
    }

    &:after {
        font-size: 16px !important;
        @media (max-width: 767px) {
            font-size: 14px !important;
        }
    }

    &.swiper-button-disabled {
        opacity: 0 !important;
    }

    @media (max-width: 767px) {
        width: 32px !important;
        height: 32px !important;
    }
}

.swiper-button-next-mobile,
.swiper-button-prev-mobile {
    width: 32px;
    height: 32px;
    background: rgba(0, 0, 0, 0.1);
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    outline: none;

    &:hover {
        background: rgba(54, 207, 201, 0.8);
        color: black;
    }
}

.stream-block {
    margin: 0;
    width: 100%;

    .ant-col {
        padding: 0;
    }

    &.full-width-height div {
        width: 100%;
        height: 100%;
    }
}

.section-account {
    padding: 30px 0;

    h4.ant-typography {
        color: @gray-8;
        font-weight: 600;
    }

    .section-promo {
        margin: 0;
    }

    &:focus:before {
        display: block;
    }
}

.section-tool {
    padding: 15px 0;

    &.section-tool-mobile {
        padding: 0;
    }

    .section-filter {
        padding: 0 0 15px;
    }
}

.section-title {
    > .ant-col {
        padding-top: 5px;
        padding-bottom: 5px;
    }
}

.section-card {
    .table-card .ant-pagination {
        text-align: center;
        float: none;
        margin: 15px 0 0;
    }

    &.card-list {
        .ant-col {
            padding-bottom: 0;
        }
    }
    button.selected-items-action {
        margin-right: 10px;
    }
}

.section-vehicles {
    padding: 25px 0;
}

.section-filter {
    padding: 15px 0;
    justify-content: space-between;

    .ant-pagination-item {
        display: none;
    }

    .ant-input {
        width: 185px;
    }

    .my-inventory-search-input {
        .ant-input {
            width: 100%;
        }
    }

    &.bottom-none {
        padding-bottom: 0;
    }

    > .ant-col {
        padding-top: 5px;
        padding-bottom: 5px;
    }
}

.section-card-item {
    > .ant-col {
        margin-bottom: 30px;
    }

    .card-item {
        height: 100%;
    }

    .card-item.card-item-width {
        margin-bottom: 0;
    }
}

.active-effect {
    box-shadow: 0px 16px 52px rgba(0, 0, 0, 0.08), 0px 12px 20px rgba(0, 0, 0, 0.04), 0px 2px 8px rgba(0, 0, 0, 0.04);

    .ant-card-meta-title {
        color: @asphalt-7;
    }
}

.card-item {
    border-radius: 12px;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
    border: none;
    overflow: hidden;

    &.active {
        &.ant-card-hoverable {
            .active-effect;
        }
    }

    &.ant-card-hoverable:hover {
        .active-effect;
        .halo-placeholder {
            &.has-halo {
                background-color: @asphalt-1;
            }
        }
        .not-active {
            color: @asphalt-7;
        }
    }

    &.card-item-width {
        max-width: 285px;
        margin: 0 auto 20px;
        @media (max-width: 768px) {
            max-width: 100%;
        }
    }

    .ant-card-meta-title {
        white-space: normal;
        font-size: 20px;
        font-weight: 600;
        line-height: 1.3;
        margin-bottom: 10px;

        .ant-tag {
            float: right;
        }
    }

    .ant-card-cover {
        position: relative;
        height: 120px;
        width: 100%;

        > div {
            background-size: cover;
            background-repeat: no-repeat;
            background-position: 50% 50%;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            top: 0;
            bottom: 0;
            display: flex;
            align-items: center;
            justify-content: center;

            &.card-cover-as-text {
                flex-direction: column;
                font-size: 30px;
                color: #000;
                background: @gray-5;
                font-weight: 500;
                opacity: 1;

                &.success {
                    background: @asphalt-6;
                    color: #fff;
                }
            }
        }

        @media (max-width: 667px) {
            aspect-ratio: auto 4/3;
            height: auto;
        }
    }

    &.ant-card-hoverable:hover {
        box-shadow: 0px 16px 52px rgba(0, 0, 0, 0.08), 0px 12px 20px rgba(0, 0, 0, 0.04),
            0px 2px 8px rgba(0, 0, 0, 0.04);

        .ant-card-meta-title {
            color: @asphalt-7;
        }
    }

    .card-bottom {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 15px;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;

        &.card-bottom-separator {
            border-top: 1px solid #ebebeb;
        }

        .ant-btn {
            margin-left: 6px;
        }
    }

    .card-price {
        font-size: 20px;
        color: @asphalt-6;

        &.disabled {
            color: @gray-6;
        }

        &.outbid {
            color: @red-6;
        }

        &.sold {
            color: @green-7;
        }

        &.cyan {
            color: @asphalt-6 !important;
        }
    }

    .ant-card-body {
        padding: 15px 15px 70px;
    }

    .btn-event,
    .btn-heart {
        position: absolute;
        top: 0;
        right: 0;
        font-size: 18px;
        height: 40px;
    }

    .holder {
        position: relative;
    }

    .ant-card-meta-detail {
        padding: 0 40px 0 0;
    }

    &.reset-padding {
        .ant-card-meta-detail {
            padding: 0;
        }
    }

    &.sold-add,
    &.sold {
        .ant-card-cover div,
        .ant-card-cover img {
            opacity: 0.4;
        }

        .ant-card-meta-title a,
        .ant-card-meta-title,
        .card-price {
            color: #8c8c8c;
        }

        .btn-heart {
            opacity: 0.5;
        }
    }

    &.sold-add {
        .ant-card-cover div,
        .ant-card-cover img {
            opacity: 0.6;
        }
    }

    .ant-tag {
        border: none;
        font-weight: 600;
        font-size: 14px;
        height: 24px;
        line-height: 24px;
        margin: 0 0 0 5px;

        &.tag-owner {
            font-size: 12px;
        }
    }

    &.time-left {
        border: 4px solid #5cdbd3;
        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
    }
}

.card-item {
    .text {
        &.black {
            color: @black;
        }
        &.gray {
            color: @gray-7;
        }
        &.blue {
            color: @blue-5;
        }
        &.orange {
            color: @orange-6;
        }
        &.volcano {
            color: @volcano-6;
        }
        &.red {
            color: @red-6;
        }
    }
}

.card-account {
    border-radius: 12px;
    border: 1px solid @gray-3;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.04);

    > .ant-card-body {
        padding: 40px 100px;
        @media (max-width: 1139px) {
            padding: 20px 40px;
        }
        @media (max-width: 767px) {
            padding: 20px;
        }
    }
}

.payment-card-block {
    background: @gray-2;
    padding: 32px 24px;
    height: 100%;
    @media (max-width: 992px) {
        height: auto;
        padding: 15px;
    }

    .card-payment {
        margin: 0 auto 20px;
    }
}

.ant-list-grid.list-payment {
    margin: 0 0px 15px;

    .ant-spin-container {
        overflow: hidden;
        display: flex;
        flex-wrap: wrap;
        > .ant-row {
            width: 100%;
            margin: 0;
        }
    }

    .ant-list-item {
        max-width: 280px;
        @media (max-width: 376px) {
            max-width: 100%;
        }
    }
}

.col-contact {
    padding: 30px 0;

    .ant-list-item {
        font-size: 16px;
        padding: 5px 0;

        a {
            font-weight: 600;
        }

        .anticon {
            font-size: 20px;
            margin-right: 10px;
        }
    }
}

// SellerVehicleDetailsPage
.anchor-nav {
    background: none;
    margin: 0 0 16px 0;
    border-bottom: 1px solid #e8e8e8;
    &.tab-full.text-center {
        .ant-anchor-link {
            padding-block: 16px;
            padding-inline: 16px;
        }
    }

    .ant-anchor-link {
        display: inline-block;
        padding: 16px 16px;
        line-height: 1.4;
        margin: 0 32px 0 0;
        position: relative;
        @media (max-width: 767px) {
            padding: 10px;
            margin: 0 5px 0 0;
        }

        &:last-child {
            margin: 0;
        }

        &.ant-anchor-link-active {
            &:before {
                content: '';
                height: 2px;
                background-color: @m-green-6;
                position: absolute;
                bottom: 0;
                right: -8px;
                width: calc(100% + 16px);
            }
        }
    }

    .ant-anchor {
        font-weight: 600;
        font-size: 16px;
        white-space: nowrap;
        overflow-x: auto;
        @media (max-width: 666px) {
            font-size: 14px;
        }
    }

    .ant-anchor-ink {
        background: none;
        display: none;
    }
}

.table-history,
.empty-vehicles {
    margin: 0 0 30px;
}

.table-history {
    .ant-table-row {
        td:last-child {
            text-align: right;
        }
    }

    .ant-table-row-cell-last {
        text-align: right;
    }

    .received-from,
    .detail-item-invoice {
        display: block;
        margin: -9px 0;

        em {
            font-style: normal;
            display: block;
            font-size: 12px;
            color: #8c8c8c;
        }
    }
}

.top-panel {
    h3.ant-typography {
        margin-bottom: 0;
    }
}

.tab-bar {
    .ant-tabs-bar {
        margin-bottom: 30px;
    }
}

.list-desc {
    color: #8c8c8c;

    .anticon {
        margin-right: 5px;
        vertical-align: middle;
    }

    .ant-tag {
        margin-left: 0;
    }

    .ant-btn {
        .anticon {
            margin: 0;
        }
    }
}

.horizontal.list-state-filter {
    .ant-select {
        font-weight: 600;
        @media (max-width: 567px) {
            width: 100% !important;
        }
    }

    .ant-list-item {
        padding: 2px 7px;
        @media (max-width: 567px) {
            width: 100%;
        }

        &.divider {
            @media (max-width: 667px) {
                border-left: none;
            }
        }
    }

    .ant-input {
        @media (max-width: 667px) {
            width: 100% !important;
        }
    }
}

.list-contacts {
    .ant-list-item {
        font-size: 16px;
        padding: 5px 0;
        justify-content: start;
        align-items: flex-start;

        a {
            font-weight: 600;
        }

        .emoji-mart-emoji,
        .anticon {
            font-size: 20px;
            margin-right: 10px;
        }
    }

    .ant-list-header {
        font-size: 12px;
        color: @gray-7;
        font-weight: 600;
        text-transform: uppercase;
    }
}

.table-card {
    .ant-table {
        padding-bottom: 20px;
    }

    a {
        color: @asphalt-6;
        font-weight: 600;
    }

    .bid {
        margin: 0 -10px 0 0;
        color: @asphalt-6;
    }

    .ant-tag {
        font-weight: 600;
        font-size: 14px;
        height: 24px;
        line-height: 24px;
        margin: 0 0 0 5px;
        border: none;
    }

    .ant-table-thead > tr > th {
        font-weight: 600;
    }

    .ant-table-thead > tr > th,
    .ant-table-thead > tr > td {
        background: none;
    }

    .text-wrapper .nav-cell {
        max-width: 160px;
        overflow: clip;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .price {
        font-size: 16px;
        color: @asphalt-6;
        font-weight: normal;

        &.no-active {
            color: #8c8c8c;
        }

        &.outbidded {
            color: @red-6;
        }
    }

    .ant-table-row {
        &:hover {
            td {
                background-color: @gray-3 !important;
            }
        }

        &.sold td {
            background-color: #fbfcfe;
            color: @gray-7;

            .price {
                color: @gray-7;
            }
        }

        &.time-left {
            td {
                &:first-child {
                    border-left: 4px solid @asphalt-6;
                    background-color: #fff;
                }
            }

            &.ant-table-row-selected {
                td:first-child {
                    background: rgba(230, 255, 251, 0.5);
                }
            }
        }
    }
}

.registration-form {
    button[type='submit'] {
        width: 100%;
        margin-bottom: 15px;
    }
    .ant-typography {
        color: @gray-8;
        a {
            font-weight: 600;
        }
    }
    .sign-txt {
        font-size: 12px;
        line-height: 1.2;
        margin: 1em 0 2em;
        color: @gray-7;
    }
    .ant-input {
        &:focus {
            box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
        }
        &::placeholder {
            color: @gray-7 !important;
        }
    }
    .has-error {
        .ant-input-prefix .anticon {
            color: #da552f;
        }
    }
}

.sign-in {
    text-align: center;
    line-height: 1.2;
}

.affix-top-tools {
    .ant-affix {
        header {
            margin: 0 -9999px;
            padding: 0 9999px;
            padding-top: 5px;
            padding-bottom: 5px;
            background: #fbfcfe;
            box-shadow: 8px 0px 4px rgba(0, 0, 0, 0.05);
            @media (min-width: 1023px) {
                .ant-typography.main-title {
                    font-size: 36px;
                }
            }
        }
        .section-title > .ant-col {
            padding-top: 0;
            padding-bottom: 0;
        }
        @media (max-width: 767px) {
            .list.text-right {
                display: none;
            }
        }
        .pagination-hide-numbers {
            @media (max-width: 992px) {
                top: -32px;
            }
        }
    }
}

.notification-item {
    padding: 10px 12px;
    color: @gray-8;
    font-weight: 600;
    &.border-bottom {
        border-bottom: 1px solid #e7e8ea;
    }
    &.border-top {
        border-top: 1px solid #e7e8ea;
    }
}

.ant-menu-overflow.ant-menu.nav {
    border-bottom: none;
    font-size: 16px;
    font-weight: 600;
    background: none;
    display: flex;
    > li {
        padding: 0;
        span > a {
            color: #435a73;
            padding: 20px;
            @media (max-width: 1023px) {
                padding: 0 10px;
            }
            &:hover {
                color: @asphalt-7;
            }
        }
        .ant-dropdown-link {
            color: #435a73;
            padding: 0 20px;
            @media (max-width: 1023px) {
                padding: 0 10px;
            }
            &:hover {
                color: @asphalt-7;
            }
        }
        .ant-menu-submenu-title {
            color: #435a73;
            font-size: 16px;
            &:hover {
                color: @asphalt-7;
            }
        }
        &.ant-menu-item {
            min-width: 72px;
            height: 66px;
            line-height: 62px;
            border-top: 2px solid transparent;
            color: #435a73;
            font-size: 16px;
            &.ant-menu-item-selected,
            &:hover {
                border-bottom: 2px solid #13c2c2;
                background-color: rgba(230, 255, 251, 0.5);
                color: @asphalt-7;
                @media (max-width: 1023px) {
                    border-bottom: none;
                }
            }
        }
    }
    .ant-menu-sub {
        .ant-menu-item {
            font-size: 16px;
            color: #435a73;
        }
    }
    .anticon {
        @media (max-width: 1023px) {
            font-size: 16px;
        }
    }
}

.top-headings {
    border-bottom: 1px solid @gray-4;
    margin: 0 0 30px;
    letter-spacing: -0.03em;
    padding: 0 0 15px;
    .ant-col:last-child {
        text-align: right;
    }
    .ant-col:first-child {
        text-align: left;
    }
    &.top-headings-tab {
        margin: 0;
        border: none;
    }
    .ant-col {
        padding: 0;
    }
    div.ant-typography {
        margin-bottom: 5px;
    }
    .ant-btn {
        margin: 0 0 8px 8px;
    }
    &.reset {
        border: none;
        margin: 0;
    }
}

.top-panel {
    margin-bottom: 20px;
    font-size: 16px;
    .ant-col:last-child {
        text-align: right;
    }
    .ant-col:first-child {
        text-align: left;
    }
    .ant-typography {
        margin: 0;
    }
}

.section-focusable {
    z-index: 1001;
    position: relative;
}

.section-focusable-background {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: hsla(0, 0%, 100%, 0.9);
    z-index: 1001;
    transition: all 0.3s;
}

.list-switch {
    .ant-list-item-meta-title {
        font-size: 16px;
        font-weight: 600;
    }
    .ant-list-item {
        padding: 25px 0;
        flex-wrap: wrap;
        &:first-child {
            padding-top: 0;
        }
        &:last-child {
            padding-bottom: 0;
        }
    }
    .switch-top {
        margin: -75px 0 20px;
        @media (max-width: 767px) {
            margin: 0 0 20px;
        }
    }
}

.switch-block-show {
    padding: 30px 0 0;
    width: 100%;
}

.add-form {
    .ant-select {
        width: 100% !important;
    }
    .ant-row {
        padding: 3px 0;
    }
}

.ant-empty.empty-vehicles {
    .ant-empty-description {
        font-size: 16px;
        font-weight: 600;
        color: @gray-8;
    }
    .ant-empty-image {
        margin-bottom: 25px;
    }
    .ant-empty-footer {
        margin-top: 30px;
    }
    .ant-typography {
        margin: 0 0 5px;
    }
}

.center-icon {
    display: flex;
    align-items: center;
    justify-content: center;
}

.btn-close-modal.ant-btn {
    color: #fff;
    font-size: 28px;
    margin: -3px 0 0;
    padding: 0;
    @media (max-width: 768px) {
        font-size: 24px;
    }
}

.ant-modal {
    .payment-card-block {
        margin: -24px -24px 20px;
    }
}

.stream-viewer {
    position: absolute;
    overflow: hidden;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    border-radius: 12px;
    background-clip: padding-box;
    background-color: black;
    z-index: 1;
}

.intermission-stream-viewer {
    display: none;
}

.stream-viewer-holder {
    width: 100%;
    height: 100%;
    .swiper-slide &.stream-viewer {
        background-color: black;
    }
}

.stream-modal {
    z-index: 1002;
}

.stream-viewer-container {
    position: relative;
    width: 640px;
    height: 480px;
    @media (max-width: 667px) {
        width: 100%;
        height: 240px;
    }
}

.modal-vehicles {
    @media (min-width: 320px) {
        width: 90vw !important;
    }
    @media (min-width: 767px) {
        width: 70vw !important;
    }
    @media (min-width: 1200px) {
        width: 60vw !important;
    }
    @media (min-width: 1920px) {
        width: 50vw !important;
    }
}

.counter-success {
    color: @m-green-8;
}

.counter-fail {
    color: @red-7;
}
