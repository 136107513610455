.clear-timing:global(.card-box) {
    margin: 0;
    :global(.card-remaining) {
        display: none;
    }
}

:global(.ant-progress) {
    line-height: 0;
}
