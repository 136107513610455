@import '@joyrideautos/web-common-components/src/styles/constants.less';

.footer {
    width: 100%;
    background: @asphalt-6;
    color: #fff;
    padding: 0;
    overflow: hidden;
    display: table-footer-group;
    height: 1%;
    a {
      color: @asphalt-1;
      font-weight: 600;
      &:hover {
        text-decoration: underline;
      }
    }
    .footer-holder {
      padding: 50px 0 35px;
    }
    .footer-bottom {
      padding: 15px 0 30px;
      border-top: 1px solid rgba(255, 255, 255, 0.1);
      .ant-col {
        @media (max-width: 767px) {
          width: 100%;
          text-align: center;
        }
      }
    }
    .footer-item {
      padding-bottom: 15px;
      .ant-typography {
        color: #fff;
        font-size: 16px;
        font-weight: 600;
      }
      a {
        color: #e6fffb;
        font-size: 16px;
        font-weight: 600;
        &:hover {
          text-decoration: underline;
        }
      }
      > div {
        padding: 4px 0;
      }
      .txt {
        font-size: 16px;
        margin-bottom: 20px;
        display: block;
        font-weight: normal;
      }
    }
    .footer-logo {
      display: block;
      margin-bottom: 15px;
    }
    .footer-info {
      @media (max-width: 767px) {
        text-align: center;
        max-width: 300px;
        margin: 0 auto 30px;
      }
    }
    .list {
      .item {
        margin-left: 25px;
        &:first-child {
          margin-left: 0;
        }
      }
    }
    .copyright {
      display: block;
      text-align: right;
      color: rgba(255, 255, 255, 0.6);
      font-size: 12px;
      @media (max-width: 767px) {
        text-align: center;
        padding: 30px 0 0;
      }
    }
}
  
.back-top.ant-back-top {
    right: 20px;
    bottom: 90px;

    @media (max-width: 767px) {
        display: none;
    }
}