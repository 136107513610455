@import '@joyrideautos/web-common-components/src/styles/constants.less';

.drop-notifications {
    width: 385px;
    @media (max-width: 375px) {
        width: 100vw;
    }
    :global(.ant-dropdown-menu-item) {
        padding: 0;
        cursor: default;
        &:hover {
            background: none;
        }
    }
    :global(.your-bid) {
        display: block;
        em {
            color: #b7b7b7;
            font-style: normal;
        }
    }
    :global(.ant-list-items) {
        height: 476px;
        overflow-y: auto;
    }
    :global(.ant-list-item) {
        display: block;
        padding: 18px 25px 0;
        :global(.ant-alert) {
            background: none;
            border: none;
        }
        :global(.ant-alert-icon) {
            left: 0;
            top: 3px;
            font-size: 21px;
            position: relative;
        }
        :global(.ant-alert-with-description) {
            padding: 0 0 15px 0px;
            :global(.ant-alert-message) {
                font-weight: 600;
                color: @gray-9;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                width: calc(100% - 20px);
            }
        }
    }
    .bottom {
        border-top: 1px solid #e7e8ea;
        padding: 10px 12px;
        text-align: center;
        font-weight: 600;
    }
    .top {
        border-bottom: 1px solid #e7e8ea;
        padding: 10px 12px;
        color: @gray-8;
        font-weight: 600;
        :global(.ant-typography) {
            color: @gray-8;
        }
        :global(.list) {
            width: 100%;
            margin: 0;
            .item {
                padding: 0 8px;
            }
        }
    }
    .radion-button {
        position: absolute;
        right: 0;
        top: 0;
        margin: 0;
    }
    :global(.anticon-setting) {
        color: @gray-8;
    }
    :global(.button-bottom) {
        background-color: #fbfcfe;
        padding: 5px 14px;
        margin: 0 -25px;
        border-top: 1px solid #e7e8ea;
    }
    :global(.ant-alert-with-description .ant-alert-description) {
        text-overflow: ellipsis;
        overflow: hidden;
    }
}

.radion-button-style {
    :global(.ant-checkbox-inner) {
        border-radius: 50%;
        &:after {
            width: 8px;
            height: 8px;
            background-color: @asphalt-6;
            border-radius: 50%;
            transform: none;
            position: absolute;
            top: 3px;
            left: 3px;
            display: table;
            border: none;
        }
    }
    :global(.ant-checkbox) {
        top: 0;
    }
    :global(.ant-checkbox-checked) {
        :global(.ant-checkbox-inner) {
            background: none;
            border-color: @asphalt-6;
        }
        &:after {
            border: none;
        }
    }
    span {
        float: right;
    }
    :global(.ant-checkbox-inner) {
        float: none;
    }
}
