@import '@joyrideautos/web-common-components/src/styles/constants.less';

.bottom-banner {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
}

:global(.a-tag-button) {
    border: 1px solid #000000;
    font-size: 14px;
    line-height: 1.5;
    height: 32px;
    padding: 4px 15px;
    border-radius: 4px;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 600;
    &:hover {
        color: @asphalt-5;
        border-color: @asphalt-5;
    }
}